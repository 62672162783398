import { QueryData } from '@supabase/supabase-js';

import { supabase } from 'hooks/useSupabase';

export const groupProgramHistoryQuery = supabase
    .from('group_program_history')
    .select(
        `*,
        group_program_history_session_details!inner(*),
        session_details(
            *,
            session_details_modules!inner(*),
            modules(*,
                exercises_frameworks(*)
            )
        )
    `
    );

export type JoinedGroupProgramHistory = QueryData<
    typeof groupProgramHistoryQuery
>;

export const repeatableSessionDetailsQuery = supabase
    .from('session_details')
    .select(
        `*,
        modules(*,
            exercises_frameworks(*)
        )
    `
    )
    .eq('is_repeatable', true);

export type JoinedRepeatableSessionDetails = QueryData<
    typeof repeatableSessionDetailsQuery
>;

export const sortSessionDetails = (
    groupProgramHistory: JoinedGroupProgramHistory | null
) =>
    groupProgramHistory == null
        ? []
        : structuredClone(
              groupProgramHistory?.[0]?.group_program_history_session_details ??
                  []
          )
              .filter(
                  // Type check is working in development, but compiler flags on build
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (sessionDetailsJoinDatum) =>
                      !sessionDetailsJoinDatum?.is_banked
              )
              .sort(
                  // Type check is working in development, but compiler flags on build
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (a, b) => (a?.session_number ?? 0) - (b?.session_number ?? 0)
              )
              // Type check is working in development, but compiler flags on build
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .map((sessionDetailsJoinDatum) =>
                  groupProgramHistory?.[0]?.session_details?.find(
                      (sessionDetailsDatum) =>
                          sessionDetailsDatum?.id ===
                          sessionDetailsJoinDatum?.session_details_id
                  )
              );

export const filterBankedSessionDetails = (
    groupProgramHistory: JoinedGroupProgramHistory | null
) =>
    groupProgramHistory == null
        ? []
        : structuredClone(
              groupProgramHistory?.[0]?.group_program_history_session_details ??
                  []
          )
              .filter(
                  // Type check is working in development, but compiler flags on build
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (sessionDetailsJoinDatum) =>
                      sessionDetailsJoinDatum?.is_banked
              )
              // Type check is working in development, but compiler flags on build
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .map((sessionDetailsJoinDatum) =>
                  groupProgramHistory?.[0]?.session_details?.find(
                      (sessionDetailsDatum) =>
                          sessionDetailsDatum?.id ===
                          sessionDetailsJoinDatum?.session_details_id
                  )
              );
