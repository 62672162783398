import styled from 'styled-components';

import { colors } from 'styles/theme';

export const ObjectiveSelectorWrapper = styled.div`
    border: 1px solid lightgrey;
    border-radius: 8px;
    background-color: ${colors.white};
    width: 100%;
    padding: 24px;
    padding-bottom: 8px;
`;
