import { Database } from 'libs/supabaseTypes';

export type FeedbackTypeEnum = Database['public']['Enums']['feedback_type'];

export const ASSESSMENT_CATEGORIES = {
    'Career Wellbeing': [
        'I am engaged in meaningful work, and I feel confident in my abilities.',
        'I invest time and energy to develop myself.',
        'I take responsibility and create opportunities to evolve my career.',
    ],
    'Emotional Resilience': [
        'I see challenge as an opportunity to expand and engage.',
        'I feel valued and affirmed and take time to value and affirm others.',
        'I have strategies for when I am stressed.',
    ],
    'Environmental Wellbeing': [
        'I have a physical space that enables me to do my best work.',
        'I live and work in a positive environment where I feel safe.',
        'I find time to connect with nature and be outdoors.',
    ],
    'Financial Wellbeing': [
        'I am appropriately compensated for my work.',
        'I feel like I have a healthy relationship with money.',
        'I am satisfied with my current financial situation.',
    ],
    'Intellectual Curiosity': [
        'I give myself permission to take risks, experiment, and be creative.',
        'I take time to engage in self-learning.',
        'I have opportunities to engage in my strengths every day.',
    ],
    'Physical Wellbeing': [
        'I eat healthy and nourishing foods.',
        'I exercise regularly and feel strong.',
        'I get sufficient sleep every night and allow my body to rest when it’s tired.',
    ],
    'Social Wellbeing': [
        'I have a strong support system.',
        'I cultivate trust and connection with others.',
        'I communicate my feelings effectively.',
    ],
    'Spiritual Awareness': [
        'I have a practice that enables me to make meaning in life.',
        'I create time and space to acknowledge and appreciate other beings.',
        'I am living a life that aligns with my personal values.',
    ],
} as const;

export const EXIT_RESPONSES = [
    'Strongly Agree',
    'Agree',
    'Neutral',
    'Disagree',
    'Strongly Disagree',
] as const;

export const RATING_RESPONSES = [
    'Very Satisfied',
    'Satisfied',
    'Neutral',
    'Unsatisfied',
    'Very Unsatisfied',
] as const;

export const SESSION_OVERVIEW_TABS = [
    'objectives',
    'review',
    'apply',
    'reflect',
    'learnings',
] as const;

export const CLARITY_SESSION_TITLE = 'Clarity Council';

export const SESSION_EXERCISES = [
    'Clarity Council',
    'Knowledge Share',
    'Coaching Fundamentals',
    'DISC',
    'Eisenhower Matrix',
    'Future Self Visualization',
    'Giving and Receiving Feedback',
    'Grand Retro',
    'Grounding Meditation',
    'Life Story Redux',
    'Limiting Beliefs',
    'Reflected Best Self',
    'Rose Bud Thorn',
    'Six Lives',
    'Timeline of Your Life',
    'Values Hierarchy',
    'Zone of Genius',
    'Other',
] as const;

export const ADMIN_TIMES = [
    '0-5 min',
    '5-10 min',
    '10-15 min',
    '15-20 min',
    '20-25 min',
    '25-30 min',
    '30+ min',
] as const;

export const ADMIN_TIME_THRESHOLDS = [
    '15-20 min',
    '20-25 min',
    '25-30 min',
    '30+ min',
];

export const ADMIN_TYPES = [
    'Reviewing session guides and prepping for sessions',
    'Filling out the recap form',
    'Sending a recap email',
    'Following up on Slack',
    'Managing different tools',
] as const;

export const PROFILE_FIELDS = [
    'headline',
    'country',
    'state',
    'city',
    'first_question_index',
    'first_question_response',
    'second_question_index',
    'second_question_response',
];

export const PROFILE_QUESTIONS = [
    {
        question: 'What do you identify as?',
        placeholderText: 'What I identify as',
    },
    {
        question: 'How do you show up?',
        placeholderText: 'How I show up as',
    },
    {
        question: 'What are your values?',
        placeholderText: 'My values',
    },
    {
        question: 'What’s an area in your life where you feel some tension?',
        placeholderText: 'An area in my life where I feel some tension',
    },
    {
        question: 'What are your most important relationships?',
        placeholderText: 'My most important relationships',
    },
    {
        question: 'What are your hobbies/interests?',
        placeholderText: 'How I spend my time and energy',
    },
    {
        question: 'What’s your reason for joining The Grand?',
        placeholderText: 'My reason for joining The Grand',
    },
] as const;

export const MERGED_PRECURSOR_GROUPS = ['PRE20', 'PRE18'];

export const KUDOS_GROUPS = [
    'G1',
    'SEV7',
    'SEV8',
    'GOLD1',
    'GOLD2',
    '776FD1',
    '776FD2',
    '776FD3',
    'FLY7',
    'FRE3',
    'REB1',
    'REB2',
    'REB3',
    'PRE11',
    'PRE12',
    'PRE15',
    'PRE16',
    'PRE17',
    'Pioneer G2', // for demoing
    'M2 Sustainable Leadership', // for demoing
];

export const KUDOS_TYPES = {
    ['Self-aware']: '',
    Empathetic: '',
    Confident: '',
    Curious: '',
    Resilient: '',
    ['Effective Coach']: '',
} as const;

export const CHARACTER_SET =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789' as const;

export const YEARS_EXPERIENCE_OPTIONS = [
    '1-5 years',
    '6-10 years',
    '10+ years',
] as const;

export const FUNDING_STAGE_OPTIONS = [
    'Pre-seed',
    'Seed',
    'Series A',
    'Series B',
    'Series C',
    'Series D',
    'Series E',
    'Series F',
    'Private',
    'Public',
    'Venture series unknown',
    'Nonprofit',
] as const;

export const EMPOYEE_COUNT_OPTIONS = [
    '1-10 FTE',
    '11-50 FTE',
    '51-200 FTE',
    '200+ FTE',
] as const;

export const DIAGNOSTIC_RATING = [
    'Strongly Disagree',
    'Disagree',
    'Somewhat Disagree',
    'Neither Agree Nor Disagree',
    'Somewhat Agree',
    'Agree',
    'Strongly Agree',
] as const;

export const FEEDBACK_TYPES: Array<FeedbackTypeEnum> = [
    'reinforcing',
    'developmental',
];

export const OPPORTUNITY_QUESTIONS = [
    'How can I help support your growth?',
    'How can I encourage you in this area?',
    'What are some ways you could work on that?',
    'What resources could I provide to help you grow?',
    'What are some ideas you have moving forward?',
    'How can I support you in this area?',
] as const;

export const RESULT_QUESTIONS = [
    'What are your thoughts?',
    'What stood out to you?',
    'What did you notice about the situation?',
    'How did it feel for you?',
] as const;
